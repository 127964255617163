import React, { useContext } from 'react'
import { Dialog, DialogContent, makeStyles, createStyles, Typography, Grid, Paper, Box, CircularProgress, DialogTitle } from '@material-ui/core'
import { useState } from 'react';
import MROButton from 'components/buttons';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { ToastMessageContext } from 'lib/contexts/message_context';
import MROTextField from 'components/form_components/TextField';




const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "450px",
                // height: "350px"
            },
        },

    })
);


function Stand(props) {

    const classes = useStyles()
    const { handleClose, eventId, currentSelection } = props
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [errors, setErrors] = useState({});

    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let valid = true;
        let error = {};
        const formData = new FormData(e.target)
        formData.append("event_id", eventId)




        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }
        if (!valid) {
            setErrors(error);
            return;
        }



        setLoading(true)
        LineMaintainenceServices.updateStandNumber(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Stand Number Updated Successfully !",
                    variant: "success",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.errors ? res.errors : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }
    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    return (
        <div>



            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="allocate engineers"
                aria-describedby="allocate engineers"
                className={classes.modal}
                container={container()}



            >
                <DialogTitle>
                    <Typography color='primary' variant='subtitle1' style={{ fontSize: '18px', textAlign: 'center' }}>Stand Number</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} >
                        <Grid item xs={12}>
                            <MROTextField
                                name={'stand_number'}
                                label={'Stand Number *'}
                                placeholder="Add aircraft Stand / Gate number"
                                error={errors.stand_number}
                                inputProps={{ maxLength: 6 }}
                                onChange={(e) => {
                                    resetErrorsHandler("stand_number")
                                }
                                }
                                defaultValue={currentSelection?.stand || ""}
                                InputLabelProps={{ shrink: true }}>
                            </MROTextField>
                        </Grid>

                        <Grid item xs={12} container direction='row' style={{ justifyContent: 'center', marginTop: '40px', marginBottom: '30px' }} >

                            <MROButton type='button' variant='contained' style={{ marginRight: '23px' }} onClick={() => {
                                handleClose()
                            }}>

                                Cancel
                            </MROButton>
                            <MROButton variant='contained' color='primary' loading={loading}
                                type="submit"
                            >
                                Complete
                            </MROButton>


                        </Grid>


                    </form>


                </DialogContent>

            </Dialog>



        </div>
    )
}

export default Stand