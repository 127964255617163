import requestrApi from "../../common/boxr_api_client";

const createEndPoints = {
    general_info: '/line_customers/create_line_customers',
    commercials: '/line_customers/create_commercial_rates'
}

function getLineCustomers(params) {
    return requestrApi({
        url: '/line_customers/listing_line_customers',
        method: 'GET',
        params: params
    });
}
function fetchDependencies(params) {
    return requestrApi({
        url: '/check_rates/fetch_dependencies',
        method: 'GET',
        params: params
    });
}
function create(formData, section) {
    return requestrApi({
        url: createEndPoints[section],
        method: 'POST',
        data: formData
    })
}

function createDelayCode(formData) {
    return requestrApi({
        url: "/line_customers/create_delay_codes",
        method: 'POST',
        data: formData
    })
}
function updateDelayCode(formData) {
    return requestrApi({
        url: "/line_customers/update_delay_codes",
        method: 'PUT',
        data: formData
    })
}

const LineCustomerService = {
    getLineCustomers,
    create,
    fetchDependencies,
    createDelayCode,
    updateDelayCode
};

export default LineCustomerService;