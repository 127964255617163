import React, { useReducer, useState, useEffect, useContext } from 'react'
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import ViewIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { ICON_COLOUR } from "lib/constants/style_constants";
import {
    Box,
    IconButton,
    Typography,
    Button,
    Grid,
    makeStyles
} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import { getOrganisationId } from "lib/utils/common_utils";
import { WorkshopDashboardServices } from 'lib/services/api/dashboard/workshop';
import { ToastMessageContext } from "lib/contexts/message_context";
import BoxpTableAction from "actions/boxp_table_actions";
import TabDetails from 'views/Operations/Workshop/TabDetails';
import moment from "moment";
import LinearProgressBar from 'components/linear_progress_bar';
import whiteSettingIcon from "../../../../assets/icons/white-settings.png";
import MROButton from 'components/buttons';
import MessagePopup from "components/universal/messagePopup";
import RedHandIcon from "assets/red_hand.png";
import useDebounce from 'lib/utils/debounce';



const useStyles = makeStyles((theme) => ({

    runButton: {
        backgroundColor: "#4FC605", color: "white",
        '&:hover': {
            backgroundColor: "#4FC605",
            opacity: 0.75,
            color: "white"
        }
    }

}));


function CommericalTableBottom(props) {
    const classes = useStyles()
    const { filterData, startDate, endDate, selectedBase, selectedFilters, actions } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext);
    const [totalCount, setTotalCount] = useState(5);
    const { data, inProgress } = tableState;
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [commercialOverviewData, setCommercialOverviewData] = useState([])
    const [currentSelection, setCurrentSelection] = useState({})
    const [action, setAction] = useState(false);
    const [runTable, setRunTable] = useState(false)
    const [searchText, setSearchText] = useState("")
    const debounceVal = useDebounce(searchText)

    // console.log("filters======", selectedBase, selectedFilters)
    const getCommercialOverview = () => {
        const params = {
            order_type: "Maintenance",
            page: page + 1,
            organisation_id: getOrganisationId(),
            table: "top",
            limit: pageSize,
            start_date: startDate ? filterData.startdate : moment().subtract(1, 'months').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),
            search: debounceVal
        }
        selectedBase.forEach((item, index) => params[`base_filter[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`customer[${[index]}]`] = item)
        setLoading(true)
        WorkshopDashboardServices.getCommercialOverView(params).then((response) => {

            setCommercialOverviewData(response.data)


            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }


    useEffect(() => {

        // getCommercialOverview()

    }, [page, pageSize, props.filterData, totalCount, debounceVal])


    const handleCommercialtableUpdate = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_type: "Maintenance",

        }
        await WorkshopDashboardServices.getUpdateCommercialOverView(params).then((res) => {
            setRunTable(true)
            getCommercialOverview()
        }).catch(() => {

        }).finally(() => {
            if (!loading) {
                setTimeout(() => {
                    setRunTable(false)
                }, 2000)

            }
        })

    }




    const handleActions = (rowData) => {
        setAction("viewCommerial");
        setCurrentSelection(rowData);
        getMaintenanceOrderList()
    }

    const getMaintenanceOrderList = () => {
        const params = {
            page: page + 1,
            limit: pageSize,
            order_type: "Maintenance",
            organisation_id: getOrganisationId(),

        };
        // selectedBase.forEach((item, index) => params[`base_filter[${[index]}]`] = item)
        BoxpTableAction.getList(dispatch, message, params, "OrderList");
    };


    const renderZeroState = (value) => {
        return (

            <div>
                <img src={RedHandIcon} height={'16px'} />
            </div>
        )


    }





    const columns = [
        {
            title: "Order #",
            field: "order_number",
            headerStyle: {
                textAlign: 'left',
                paddingLeft: 24
            },
            cellStyle: {
                paddingLeft: 24,
            },

        },
        {
            title: "Customer",
            field: "customer",
            width: '10%',
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {
                width: '10%',
                textAlign: 'left'
            },
        },
        {
            title: "PO#",
            field: "labour",
            headerStyle: {
                textAlign: 'left',
            },
        },
        {
            title: "Description",
            field: "description",
            width: '20%',
            headerStyle: {
                width: '20%',
                textAlign: 'left',
            },
            cellStyle: {
                width: '20%',
                textAlign: 'left',
            },

        },
        {
            title: "Work/Status",
            field: "labour",
            headerStyle: {
                textAlign: 'left',
            },
        }, {
            title: "Status",
            field: "labour",
            headerStyle: {
                textAlign: 'left',
            },
        },
        {
            title: "TAT",
            field: "actions",
            headerStyle: {
                textAlign: 'left',
            },
            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.actions)}
                        width='70px'
                        barHeight='20px'
                        bgColor={Number(rowData.actions) === 0 ? "#FFFFFF" : '#4FC605'}
                        color={"#4D4F5C"}
                    />
                </div>
        },
        {
            title: "Action Progress",
            field: "stages",
            headerStyle: {
                textAlign: 'left',
            },
            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.stages)}
                        width='70px'
                        barHeight='20px'
                        bgColor={Number(rowData.stages) === 0 ? "#FFFFFF" : '#4FC605'}
                        color={"#4D4F5C"}
                    />
                </div>

        },
        {
            title: "Stage Progress",
            field: "labour",
            headerStyle: {
                textAlign: 'left',
            },

            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.labour)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(Number(rowData.labour) > 0 && Number(rowData.labour) < 75) ? '#4FC605' : (Number(rowData.labour) >= 75 && Number(rowData.labour) < 100) ? '#FFB300' : Number(rowData.labour) >= 100 ? '#FF0202' : '#fff'}
                        color={Number(rowData.labour) >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>
        }, {
            title: "Labour",
            field: "labour",
            headerStyle: {
                textAlign: 'left',
            },

            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.labour)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(Number(rowData.labour) > 0 && Number(rowData.labour) < 75) ? '#4FC605' : (Number(rowData.labour) >= 75 && Number(rowData.labour) < 100) ? '#FFB300' : Number(rowData.labour) >= 100 ? '#FF0202' : '#fff'}
                        color={Number(rowData.labour) >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>
        },
        {
            title: "Approval Var",
            field: "parts",



        },


        // {
        //     title: "Action",
        //     field: "action",
        //     render: (rowData) => (
        //         <Box>
        //             <IconButton
        //                 // disabled={!props.permission.write}
        //                 onClick={() => {
        //                     handleActions(rowData)

        //                 }}
        //             >
        //                 <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
        //             </IconButton>
        //         </Box>
        //     ),
        // },
    ];





    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: true,
        searchText: debounceVal,

    };
    return (
        <Box p={1}>

            <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                icons={tableIcons}
                title={
                    <div style={{ width: '290px' }}>
                        <Typography color="primary" variant="subtitle2" style={{ textAlign: "left" }}>
                            In Progress Overview
                        </Typography>
                        <i >Last updated: {commercialOverviewData.last_updated_at ?? '-'}  </i>
                    </div>
                }
                isLoading={loading}
                data={commercialOverviewData.listing || []}
                columns={columns}
                options={tableOptions}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize);
                }}
                onSearchChange={(search) => {
                    setLoading(true)
                    setPage(0);
                    setSearchText(search);

                }}
                totalCount={totalCount}
                page={page}
                actions={[
                    {
                        icon: 'Add',
                        tooltip: 'Add User',
                        isFreeAction: true,
                    }
                ]}
                components={{
                    Actions: props => (
                        <Grid container>
                            <Grid style={{ width: '10px' }}>{" "}</Grid>
                            <MROButton startIcon={<img
                                width="25px"
                                height="25px"
                                src={whiteSettingIcon}
                                alt="icon"
                            // style={{ paddingLeft: '20px' }}
                            />}
                                variant="contained"
                                className={classes.runButton}
                                onClick={() => {
                                    setRunTable(true)
                                    setPage(0)
                                    handleCommercialtableUpdate()
                                }}
                            >
                                Refresh Data
                            </MROButton>
                        </Grid>
                    ),
                }}
            />
            {
                action && (
                    <TabDetails
                        permission={props.permission}
                        handleClose={() => setAction(null)}
                        action={"Unlock"}
                        currentSelection={currentSelection}
                        bases={props.bases}
                        lineCustomers={props.customerDetails?.customers}
                        tabId={20}
                        order_type={1}
                        setAction={setAction}
                        data={data.data}
                        getMaintenanceOrderList={getMaintenanceOrderList}
                        type={"dashboardCommercial"}
                    // paginationReset={paginationReset}
                    />
                )
            }
            {runTable &&
                <MessagePopup
                    handleClose={() => setRunTable(false)}
                    open={runTable}
                    topTitle={"Updating Commercial Overview table"}
                    bottomTitle={"Please be patient, this will take a few moments"}
                />}
        </Box>
    )
}

export default React.memo(CommericalTableBottom)