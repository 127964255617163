import requestApi from "../common/boxp_api_client";


const getOrderOverView = (params) => {
    return requestApi({
        url: "/orders_overview",
        method: "GET",
        params,
    })
}
const getCommercialOverView = (params) => {
    return requestApi({
        url: "/commercial_overview",
        method: "GET",
        params,
    })
}
const getUpdateCommercialOverView = (params) => {
    return requestApi({
        url: "/update_commercial_overview",
        method: "GET",
        params,
    })
}

//storagedashboard Api
const getStorageDetails = (params) => {
    return requestApi({
        url: "/storage_details",
        method: "GET",
        params,
    })
}
const getStorageActionDueListing = (params) => {
    return requestApi({
        url: "/action_due_listing",
        method: "GET",
        params,
    })
}

//New Dashboard End point


const getCompletedOrders = (params) => {
    return requestApi({
        url: "/completed_orders_value",
        method: "GET",
        params,
    })
}
const getTweleveMonthsOrder = (params) => {
    return requestApi({
        url: "/last_twelve_months_graph",
        method: "GET",
        params,
    })
}

const getInprogressWorkOrder = (params) => {
    return requestApi({
        url: "/inprogress_orders",
        method: "GET",
        params,
    })
}







export const WorkshopDashboardServices = {
    getOrderOverView,
    getCommercialOverView,
    getStorageDetails,
    getStorageActionDueListing,
    getUpdateCommercialOverView,
    getCompletedOrders,
    getTweleveMonthsOrder,
    getInprogressWorkOrder

}